import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from "@angular/core";

@Directive({
  selector: "[yrResize]",
  standalone: true,
})
export class ResizeDirective implements OnInit, OnDestroy {
  @Output()
  resized = new EventEmitter<YrResizeEvent>();

  private readonly resizeObserver;

  constructor(private readonly host: ElementRef<HTMLElement>) {
    this.resizeObserver = new ResizeObserver((entries) => {
      if (entries.length == 1) {
        this.resized.emit({
          // TODO: MAYBE USE NGZone.run (was required when we implemetned #81)
          width: entries[0].contentRect.width,
          height: entries[0].contentRect.height,
          x: entries[0].contentRect.x,
          y: entries[0].contentRect.y,
        });
      }
    });
  }

  ngOnInit() {
    this.resizeObserver.observe(this.host.nativeElement);
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }
}

export interface YrResizeEvent {
  width: number;
  height: number;
  x: number;
  y: number;
}
