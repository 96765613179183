<h1 mat-dialog-title *ngIf="data.title">
  {{ data.title | translate }}
</h1>
<div mat-dialog-content>
  {{ data.content | translate }}
</div>
<mat-dialog-actions class="md-actions" align="end">
  <button mat-raised-button [mat-dialog-close]="false">
    {{ "confirmationDialog.no" | translate }}
  </button>
  <button
    mat-raised-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    color="primary"
  >
    {{ "confirmationDialog.yes" | translate }}
  </button>
</mat-dialog-actions>
