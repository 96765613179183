export interface MusicScore {
  name: string;
  nameOfMusicPiece: string;
  downloadLink?: string;
  numberOfPages: number;
}

export const EmptyMusicScore: MusicScore = {
  name: "",
  nameOfMusicPiece: "",
  downloadLink: "",
  numberOfPages: 0,
};
