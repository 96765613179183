import { DateTime } from "luxon";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UnitService {
  constructor() {}

  timestampMsToString(
    timestampMs: number,
    configuration?: {
      useMinutesFormat: boolean;
      alwaysShowMinutes: boolean;
    }
  ): string {
    // for now we format our ms as mm:ss or ss if we have < 1m
    const timestampSec = timestampMs / 1000;
    if (configuration?.useMinutesFormat || false) {
      const min = Math.floor(timestampSec / 60);
      const sec = Math.floor(timestampSec - min * 60);
      const minString = min < 10 ? `0${min}` : `${min}`;
      const secString = sec < 10 ? `0${sec}` : `${sec}`;

      if (min > 0 || configuration.alwaysShowMinutes) {
        return `${minString}:${secString}`;
      } else {
        return secString;
      }
    } else {
      const sec = Math.round(timestampSec);
      return `${sec}`;
    }
  }

  parseOptionalApiDate(date: string | undefined): Date | undefined {
    if (date) {
      return this.parseApiDate(date);
    }
    return;
  }

  parseApiDate(date: string): Date {
    return DateTime.fromISO(date.replace(" ", "T")).toJSDate();
  }

  dateToApiDate(date: Date): string {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd HH:mm:ss");
  }

  bytesToMegaBytes(bytes: number): number {
    return Math.round(bytes / 1024 / 1024);
  }

  millisecondsAsHours(practiceDurationThisMonthMs: number): number {
    return +(practiceDurationThisMonthMs / 1000 / 60 / 60).toFixed(2);
  }
}
