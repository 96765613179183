import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-licensed-used-resource",
  templateUrl: "./licensed-used-resource.component.html",
  styleUrls: ["./licensed-used-resource.component.scss"],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class LicensedUsedResourceComponent implements OnInit {
  @Input()
  title: string = "";

  @Input()
  unit: string = "";

  _used: number = 0;

  @Input()
  set used(value: number) {
    this._used = value > 0 ? value : 0;
  }

  get used() {
    return this._used;
  }

  @Input()
  available: number | "unlimited" = "unlimited";

  constructor() {}

  ngOnInit(): void {}

  normalizedValue(): number {
    if (this.available != "unlimited") {
      if (this.available > 0) {
        return (this.used / this.available) * 100;
      }
      return 0;
    }
    return 100;
  }
}
