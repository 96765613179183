import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { InformationDialogData } from "src/app/models/dialog/information-dialog-data";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-information-dialog",
  templateUrl: "./information-dialog.component.html",
  styleUrls: ["./information-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class InformationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: InformationDialogData,
  ) {}
  ngOnInit(): void {}
}
