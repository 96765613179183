export interface MusicPiece {
  name: string;
  nameOfPiece: string;
  lastTimeViewed?: Date;
  creation: Date;
  isShared: boolean;
  sharedFrom?: string;
  isCopy: boolean;
  acceptShareTerms: boolean;
  instrument: string;
  temperament: string;
  artist: string;
  description: string;
  collection: string;
  uploadFinished: boolean;
  downloadLink: string;
  contentType: string;
}

export const EmptyMusicPiece: MusicPiece = {
  name: "",
  nameOfPiece: "",
  creation: new Date(),
  isShared: false,
  isCopy: false,
  acceptShareTerms: false,
  artist: "",
  collection: "",
  description: "",
  instrument: "",
  temperament: "",
  contentType: "",
  downloadLink: "",
  uploadFinished: false,
};
