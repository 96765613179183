<div fxLayout="column" fxLayoutAlign="start stretch">
  <div
    yrResize
    (resized)="handleResized($event)"
    class="pdf-container"
    fxLayout="column"
    fxLayoutAlign="start center"
  >
    <yr-pdf-viewer
      *ngIf="musicScore.downloadLink"
      [srcUrl]="musicScore.downloadLink"
      [width]="containerWidth"
      [height]="containerHeight"
      [pageNumber]="page"
      (pdfLoaded)="handlePdfLoaded($event)"
      (pageChanged)="handlePageChanged($event)"
    ></yr-pdf-viewer>
  </div>

  <div *ngIf="page" fxLayout="row" fxLayoutAlign="center center">
    <button
      mat-icon-button
      (click)="previousPage()"
      [disabled]="!hasPreviousPage()"
      color="primary"
    >
      <mat-icon>chevron_left</mat-icon>
    </button>
    <div class="page-number">{{ page }}</div>
    <button
      mat-icon-button
      (click)="nextPage()"
      [disabled]="!hasNextPage()"
      color="primary"
    >
      <mat-icon>chevron_right</mat-icon>
    </button>
  </div>
</div>
