import { SubscriptionLike } from "rxjs";

export class SubscriptionService {
  private subs: SubscriptionLike[] = [];

  constructor() {}

  add(sub: SubscriptionLike): void {
    this.subs.push(sub);
  }

  addMany(...subs: SubscriptionLike[]): void {
    subs.forEach((s) => this.add(s));
  }

  unsubscribeAll() {
    this.subs.forEach((sub) => sub.unsubscribe());
  }
}
