import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from "@angular/core";

import {
  getDocument,
  GlobalWorkerOptions,
  PDFDocumentProxy,
  PDFPageProxy,
  RenderTask,
  RenderingCancelledException,
} from "pdfjs-dist";

// @ts-ignore
// import * as pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry"; // OLD
import { version } from "pdfjs-dist";
import { RenderParameters } from "pdfjs-dist/types/src/display/api";

import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { MaterialModule } from "src/app/modules/material.module";
// GlobalWorkerOptions.workerSrc = pdfjsWorker; // OLD

GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${version}/build/pdf.worker.min.js`; // new

@Component({
  selector: "yr-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: [],
  standalone: true,
  imports: [CommonModule, MaterialModule],
})
export class PdfViewerComponent implements AfterViewInit {
  private _srcUrl: string = "";
  isLoaded = false;
  private pdf?: PDFDocumentProxy;
  private currentPage?: PDFPageProxy;

  private currentPageInfo?: PageInfo;

  private renderHelper?: RenderHelper;

  @ViewChild("pdf")
  canvas?: ElementRef<HTMLCanvasElement>;

  @Input()
  set srcUrl(url: string) {
    if (url != this._srcUrl) {
      this._srcUrl = url;
      this.load();
    }
  }

  get srcUrl() {
    return this._srcUrl;
  }

  _width = 0;

  @Input()
  set width(width: number) {
    if (this._width != width) {
      this._width = width;
      this.renderPage();
    }
  }

  get width() {
    return this._width;
  }

  _height = 0;

  @Input()
  set height(height: number) {
    if (height != this._height) {
      this._height = height;
      this.renderPage();
    }
  }

  get height() {
    return this._height;
  }

  @Input()
  set pageNumber(page: number) {
    if (page != (this.currentPageInfo?.pageNumber || -1)) {
      this.showPage(page);
    }
  }

  @Output()
  pdfLoaded = new EventEmitter<PDFInfo>();

  @Output()
  pageChanged = new EventEmitter<{ page: PageInfo; oldPage?: PageInfo }>();

  constructor() {}

  ngAfterViewInit(): void {
    if (this.canvas) {
      this.renderHelper = new RenderHelper(this.canvas.nativeElement);
    } else {
      console.error("canvas not found");
    }
  }

  private reset() {
    this.isLoaded = false;
    this.pdf = undefined;
    this.currentPage = undefined;
    this.currentPageInfo = undefined;
  }

  private async loadPdf(srcUrl: string): Promise<PDFDocumentProxy> {
    const task = getDocument(srcUrl);
    const pdf = await task.promise;
    console.debug(`pdf loaded with ${pdf.numPages} pages`);
    return pdf;
  }

  private async showFirstPage() {
    await this.showPage(1);
  }

  private getPageInfoFrom(page: PDFPageProxy): Partial<PageInfo> {
    console.debug(`getPageInfoFrom: ${page.view}`);

    const devicePixelRatio = /* window.devicePixelRatio || */ 1;

    const viewPort = page.getViewport({
      scale: devicePixelRatio,
      rotation: page.rotate,
    });
    // console.log("getPageInfoFrom: page.getViewPort()", viewPort);

    return {
      pageNumber: page.pageNumber,
      width: viewPort.width,
      height: viewPort.height,
      rotation: page.rotate,
    };
  }

  private async showPage(pageNumber: number) {
    if (this.pdf && pageNumber <= this.pdf.numPages && pageNumber > 0) {
      const page = await this.pdf.getPage(pageNumber);
      const oldPage = this.currentPageInfo;
      this.currentPage = page;

      const pageInfo = {
        ...this.getPageInfoFrom(page),
        totalPages: this.pdf.numPages,
      } as PageInfo;
      this.currentPageInfo = pageInfo;

      this.renderPage();

      this.pageChanged.emit({
        page: pageInfo,
        oldPage: oldPage,
      });
    } else {
      console.error(`page ${pageNumber} is out of bounds (pdf: ${this.pdf})`);
    }
  }

  private renderPage() {
    if (this.currentPageInfo && this.renderHelper && this.currentPage) {
      this.renderHelper.render(this.currentPage, this.currentPageInfo, {
        width: this.width,
        height: this.height,
      });
    }
  }

  private async load() {
    this.reset();

    this.pdf = await this.loadPdf(this.srcUrl);

    this.pdfLoaded.emit({
      numPages: this.pdf.numPages,
      url: this.srcUrl,
    });

    this.isLoaded = true;

    await this.showFirstPage();
  }
}

export interface PDFInfo {
  numPages: number;
  url: string;
}

export interface PageInfo {
  pageNumber: number;
  totalPages: number;
  width: number;
  height: number;
  rotation: number;
}

class RenderHelper {
  private readonly renderQueue = new Subject<{
    page: PDFPageProxy;
    pageInfo: PageInfo;
    container: { width: number; height: number };
  }>();

  private currentRenderTask?: RenderTask;
  private readonly context;

  constructor(private readonly canvas: HTMLCanvasElement) {
    this.context = this.canvas.getContext("2d")!;

    this.renderQueue.pipe(debounceTime(20)).subscribe((task) => {
      this.performRenderPage(task.page, task.pageInfo, task.container);
    });
  }

  private calculateScale(
    container: { width: number; height: number },
    pageInfo: PageInfo,
  ): number {
    const isPageNarrowerAsContainer = (
      pageInfo: PageInfo,
      container: { width: number; height: number },
    ) => {
      const containerAspectRatio = container.width / container.height;
      const pageAspectRatio = pageInfo.width / pageInfo.height;
      return pageAspectRatio < containerAspectRatio;
    };

    const scalingFactor = (container: number, page: number) => {
      return container / page;
    };

    if (isPageNarrowerAsContainer(pageInfo, container)) {
      const heightScale = scalingFactor(container.height, pageInfo.height);
      console.debug(`renderPage - calculateScale: heightScale(${heightScale})`);
      return heightScale;
    } else {
      const widthScale = scalingFactor(container.width, pageInfo.width);
      console.debug(`renderPage - calculateScale: widthScale(${widthScale})`);
      return widthScale;
    }
  }

  private async performRenderPage(
    page: PDFPageProxy,
    pageInfo: PageInfo,
    container: { width: number; height: number },
  ) {
    console.debug(
      `called: renderPage: page ${pageInfo.width}x${pageInfo.height} into container${container.width}x${container.height}`,
    );

    if (page && pageInfo && container.width > 0 && container.height > 0) {
      // Support high DPI screens
      const deviceAdditionalRenderScale = window.devicePixelRatio || 1;
      const scaleRequiredToFitContainer = this.calculateScale(
        container,
        pageInfo,
      );

      const viewport = page.getViewport({
        scale: scaleRequiredToFitContainer,
      });

      try {
        this.canvas.width = viewport.width * deviceAdditionalRenderScale;
        this.canvas.height = viewport.height * deviceAdditionalRenderScale;
        this.canvas.style.width = `${viewport.width}px`;
        this.canvas.style.height = `${viewport.height}px`;

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        const renderContext = {
          canvasContext: this.context,
          viewport: viewport,
        } as RenderParameters;

        this.context.scale(
          deviceAdditionalRenderScale,
          deviceAdditionalRenderScale,
        );

        if (this.currentRenderTask) {
          this.currentRenderTask.cancel();
        }
        this.currentRenderTask = page.render(renderContext);
        await this.currentRenderTask.promise;

        this.currentRenderTask = undefined;
        console.debug(`renderPage ${pageInfo.pageNumber} rendered`);
      } catch (e) {
        if (e instanceof RenderingCancelledException) {
          console.debug(`rendering cancelled for page ${pageInfo}`, e);
        } else {
          console.error(`error rendering page ${pageInfo}`, e);
        }
      }
    }
  }

  render(
    page: PDFPageProxy,
    pageInfo: PageInfo,
    container: { width: number; height: number },
  ) {
    this.renderQueue.next({ page, pageInfo, container });
  }
}
