import { Component, Input, NgZone, OnDestroy, OnInit } from "@angular/core";
import {
  MusicScore,
  EmptyMusicScore,
} from "src/app/models/music-score/music-score";

import { BehaviorSubject } from "rxjs";
import { throttleTime } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/ngrx/reducers";
import { SubscriptionService } from "src/app/services/subscription/subscription.service";
import { PageBreak } from "src/app/models/page-break/page-break";
import {
  selectHasAutoPageFlip,
  selectPageBreaks,
} from "src/app/ngrx/reducers/player.reducer";
import { PlayerActions } from "src/app/ngrx/actions";
import {
  PDFInfo,
  PageInfo,
  PdfViewerComponent,
} from "../../common/pdf-viewer/pdf-viewer.component";
import {
  ResizeDirective,
  YrResizeEvent,
} from "src/app/directives/resize.directive";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-music-score",
  templateUrl: "./music-score.component.html",
  styleUrls: ["./music-score.component.scss"],
  standalone: true,
  imports: [CommonModule, ResizeDirective, PdfViewerComponent, MaterialModule],
})
export class MusicScoreComponent implements OnInit, OnDestroy {
  // required for debounce: auto page flip
  private currentMsSubject = new BehaviorSubject<number>(0);

  @Input()
  musicScore: MusicScore = EmptyMusicScore;

  pageBreaks: PageBreak[] = [];

  @Input()
  set currentMs(ms: number) {
    this.currentMsSubject.next(ms);
  }

  hasAutoPageFlip: boolean = true;

  @Input()
  allowedPagesToDisplay: number = 10000;

  set page(page: number) {
    if (page != this._page && page > 0 && page <= this.numPages) {
      console.log(`set: page (${page})`);
      this._page = page;
    }
  }

  get page() {
    return this._page;
  }

  currentPageInfo?: PageInfo;

  private _page: number = 0;

  numPages: number = 0;

  private readonly subscriptionService = new SubscriptionService();

  containerWidth = 0;
  containerHeight = 0;

  @Input()
  fitToWidth = true;

  private pdfLoaded = false;

  constructor(
    private readonly store: Store<AppState>,
    private readonly ngZone: NgZone,
  ) {}

  ngOnInit(): void {
    this.currentMsSubject
      .pipe(throttleTime(500))
      .subscribe(() => this.handleAutoPageFlip());

    this.selectPageBreaks();
    this.selectHasAutoPageFlip();
  }

  ngOnDestroy(): void {
    this.subscriptionService.unsubscribeAll();
  }

  private selectPageBreaks() {
    this.subscriptionService.add(
      this.store.select(selectPageBreaks).subscribe((pbs) => {
        this.pageBreaks = pbs;
        this.handleAutoPageFlip();
      }),
    );
  }

  private selectHasAutoPageFlip() {
    this.store.select(selectHasAutoPageFlip).subscribe((hasAutoPageFlip) => {
      this.hasAutoPageFlip = hasAutoPageFlip;
      this.handleAutoPageFlip();
    });
  }

  downloadLink(): string {
    return this.musicScore.downloadLink || "";
  }

  previousPage() {
    if (this.hasPreviousPage()) {
      this.page--;
    }
  }

  hasPreviousPage() {
    return this.page > 1;
  }

  nextPage() {
    if (this.hasNextPage()) {
      this.page++;
    }
  }

  hasNextPage(): boolean {
    return this.page < this.numPages;
  }

  handlePdfLoaded(pdf: PDFInfo) {
    this.numPages = pdf.numPages;
    this.pdfLoaded = true;
  }

  handlePageChanged(event: { page: PageInfo; oldPage?: PageInfo }) {
    this.page = event.page.pageNumber;
    this.currentPageInfo = event.page;

    this.calculatePdfContainerSize();

    console.log(`handlePageChanged(${event.page.pageNumber})`);

    this.notifyDisplayedPageChange(
      this.page,
      event.oldPage?.pageNumber || 0,
      0,
      0,
      this.containerWidth,
      this.containerHeight,
    );
  }

  handleResized(event: YrResizeEvent) {
    console.debug(`handleResized(${event.width}, ${event.height})`);
    if (!this.pdfLoaded) return; // guard clause

    this.containerWidth = event.width;
    this.containerHeight = event.height;

    this.calculatePdfContainerSize();

    this.notifyDisplayedPageChange(
      this.page,
      this.page,
      event.x,
      event.y,
      this.containerWidth,
      this.containerHeight,
    );
  }

  private calculatePdfContainerSize() {
    if (this.currentPageInfo) {
      const aspectRatio =
        this.currentPageInfo.width / this.currentPageInfo.height;
      if (this.fitToWidth) {
        this.containerHeight = this.containerWidth / aspectRatio;
      } else {
        this.containerWidth = this.containerHeight / aspectRatio;
      }
    }
  }

  private notifyDisplayedPageChange(
    page: number,
    previousPage: number,
    top: number,
    left: number,
    width: number,
    height: number,
  ): void {
    console.log(
      `notifyDisplayedPageChange: ${page} was: ${previousPage}, ${top}, ${left}, ${width}, ${height}`,
    );
    this.ngZone.run(() => {
      this.store.dispatch(
        PlayerActions.displayedPageChanged({
          displayedPage: {
            pageNumber: page,
            width: Math.round(width),
            height: Math.round(height),
            left: Math.round(left),
            top: Math.round(top),
          },
          previousPageNumber: previousPage,
        }),
      );
    });
  }

  handleAutoPageFlip() {
    if (this.hasAutoPageFlip) {
      const pageBreaksBeforeCurrentMs = this.pageBreaks.filter(
        (pb) => pb.timestampMs < this.currentMsSubject.value,
      ).length;
      this.page = pageBreaksBeforeCurrentMs + 1;
    }
  }

  handleAutoPageFlipChange(hasAutoPageFlip: boolean) {
    this.hasAutoPageFlip = hasAutoPageFlip;
    if (hasAutoPageFlip) {
      this.handleAutoPageFlip();
    }
  }
}

export interface DisplayedPage {
  pageNumber: number;
  width: number;
  height: number;
  left: number;
  top: number;
}

export const EmptyDisplayedPage: DisplayedPage = {
  pageNumber: 0,
  width: 0,
  height: 0,
  top: 0,
  left: 0,
};
