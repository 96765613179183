export interface Recording {
  name: string;
  musicPieceName: string;
  downloadLink?: string;
  contentType?: string;
  rotation: 0 | 90 | 180;
  mirrorVertical: boolean;
  mirrorHorizontal: boolean;
  renderFinished: boolean;
  uploadFinished: boolean;
  contentTypeOriginalFile?: string;
  useMultipleAudioTracks: boolean;
}

export const EmptyRecording: Recording = {
  name: "",
  musicPieceName: "",
  downloadLink: "",
  contentType: "",
  mirrorHorizontal: false,
  mirrorVertical: false,
  rotation: 0,
  renderFinished: false,
  uploadFinished: false,
  useMultipleAudioTracks: false,
};
