<div fxLayout="column">
  <span>{{ title }}</span>
  <div>
    <mat-progress-bar
      [value]="normalizedValue()"
      [color]="
        normalizedValue() > 80 && available != 'unlimited' ? 'warn' : 'primary'
      "
    ></mat-progress-bar>
    <span class="used-text">({{ used }} {{ unit }}</span>
    <span class="used-text" *ngIf="available != 'unlimited'">
      / {{ available }} {{ unit }}</span
    >
    <span class="used-text">)</span>
  </div>
</div>
