import { CommonModule } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { TranslateModule } from "@ngx-translate/core";
import { ConfirmationDialogData } from "src/app/models/dialog/confirmation-dialog-data";
import { MaterialModule } from "src/app/modules/material.module";

@Component({
  selector: "yr-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.scss"],
  standalone: true,
  imports: [CommonModule, TranslateModule, MaterialModule],
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public readonly data: ConfirmationDialogData,
  ) {}

  ngOnInit(): void {}
}

export class ConfirmationDialogHelper {
  constructor(
    private readonly matDialog: MatDialog,
    private readonly title: string,
    private readonly content: string,
  ) {}

  show(confirmedCallback: () => void) {
    const ref = this.matDialog.open<
      ConfirmationDialogComponent,
      ConfirmationDialogData,
      boolean
    >(ConfirmationDialogComponent, {
      width: "600px",
      data: {
        title: this.title,
        content: this.content,
      },
    });

    ref.afterClosed().subscribe((result) => {
      if (result) {
        confirmedCallback();
      }
    });
  }
}
